<template>
	<div class="animated fadeIn">
		<div class="row">
			<div class="col">
				<div
					v-if="$can('cash_payments', 'update') && status === statuses.pending"
					class="container mb-2 mt-2 float-right">
					<b-button
						:disabled="!idsComputed.length"
						class="bg-success-alt btn ml-2 float-right"
						variant="success"
						@click="proccessSelected('approve')">
						{{ translate('approve_selected') }}
					</b-button>
					<b-button
						:disabled="!idsComputed.length"
						variant="danger"
						class="btn float-right"
						@click="proccessSelected('reject')">
						{{ translate('reject_selected') }}
					</b-button>
				</div>
			</div>
		</div>

		<div class="table-responsive">
			<table class="table table-hover table-striped text-nowrap">
				<thead>
					<tr>
						<th
							v-if="$can('cash_payments', 'update') && status === statuses.pending"
							class="border-top-0" />
						<th class="border-top-0">
							#
						</th>
						<th
							class="border-top-0 text-left">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('id')">-->
							{{ translate('order_id') }}
							<!--<sort field="id" />-->
						</th>
						<th
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('user_id')">-->
							{{ translate('user_id') }}
							<!--<sort field="user_id" />-->
						</th>
						<th
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('users.username')">-->
							{{ translate('username') }}
							<!--<sort field="users.username" />-->
						</th>
						<th
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('users.name')">-->
							{{ translate('name') }}
							<!--<sort field="users.name" />-->
						</th>
						<th
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('email')">-->
							{{ translate('email') }}
							<!--<sort field="email" />-->
						</th>
						<th
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('country')">-->
							{{ translate('country') }}
							<!--<sort field="country" />-->
						</th>
						<th
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('date')">-->
							{{ translate('date_time') }}
							<!--<sort field="date" />-->
						</th>
						<th
							v-if="status === statuses.approved"
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('updated_at')">-->
							{{ translate('approved_date_time') }}
							<!--<sort field="updated_at" />-->
						</th>
						<th
							v-if="status === statuses.rejected"
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('updated_at')">-->
							{{ translate('rejected_date_time') }}
							<!--<sort field="updated_at" />-->
						</th>
						<th
							class="border-top-0 text-right">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('total_amount')">-->
							{{ translate('total') }}
							<!--<sort field="total_amount" />-->
						</th>
					</tr>
				</thead>
				<tbody v-if="!loading && hasData">
					<tr
						v-for="(item, index) in data"
						:key="index">
						<td
							v-if="$can('cash_payments', 'update') && status === statuses.pending"
							class="align-middle">
							<input
								v-model="ids[item.id]"
								type="checkbox">
						</td>
						<td class="align-middle">
							{{ ((pagination.per_page * pagination.current_page) - pagination.per_page) + (index + 1) }}
						</td>
						<td class="align-middle text-left">
							{{ item.id }}
						</td>
						<td class="align-middle">
							<span
								v-if="item.attributes.type === distributor"
								class="badge badge-primary pointer"
								@click="toDashboard({ distributorId: item.attributes.user_id.toString() })">
								{{ item.attributes.user_id }}
							</span>
							<span
								v-else
								class="badge badge-primary">
								{{ item.attributes.user_id }}
							</span>
						</td>
						<td class="align-middle">
							{{ item.attributes.username }}
						</td>
						<td class="align-middle">
							{{ item.attributes.full_name }}
						</td>
						<td class="align-middle">
							{{ item.attributes.email }}
						</td>
						<td class="align-middle">
							<img
								v-b-tooltip.hover
								class="ml-3"
								:title="translate(item.attributes.country.toLowerCase())"
								:src="require(`@/assets/images/common/countries/select/${item.attributes.country ? item.attributes.country.toUpperCase() : 'default'}.png`)"
								:alt="translate(item.attributes.country.toLowerCase())">
						</td>
						<td
							class="align-middle">
							{{ item.attributes.date.date ? $moment(item.attributes.date.date).format(dateFormat) : $moment(item.attributes.date).format(dateFormat) }}
						</td>
						<td
							v-if="status !== statuses.pending"
							class="align-middle">
							{{ item.attributes.updated_at.date ? $moment(item.attributes.updated_at.date).format(dateFormat) : $moment(item.attributes.updated_at).format(dateFormat) }}
						</td>
						<td class="align-middle text-right">
							{{ item.attributes.total_amount }}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasData" />
		<b-pagination
			v-if="pagination.total_pages > 1"
			v-model="pagination.current_page"
			:total-rows="pagination.total"
			:per-page="pagination.per_page"
			align="center"
			@change="getDataFiltered" />
	</div>
</template>
<script>
import { PAGINATION } from '@/settings/RequestReply';
import { Grids, Countries, CashPayments } from '@/translations';
import { YMDHMS_FORMAT } from '@/settings/Dates';
import { CASH_PAYMENT_STATUSES } from '@/settings/CashPayments';
import { admin, distributor } from '@/settings/Roles';
import FiltersParams from '@/mixins/FiltersParams';
import DashboardRedirect from '@/mixins/DashboardRedirect';
import CashPayment from '@/util/Report';

export default {
	name: 'CashPaymentList',
	messages: [Grids, Countries, CashPayments],
	mixins: [FiltersParams, DashboardRedirect],
	props: {
		status: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			cashPayments: new CashPayment(),
			processPayments: new CashPayment(),
			alert: new this.$Alert(),
			dateFormat: YMDHMS_FORMAT,
			ids: [],
			admin,
			distributor,
		};
	},
	computed: {
		loading() {
			return !!this.cashPayments.data.loading;
		},
		errors() {
			return this.cashPayments.data.errors;
		},
		pagination() {
			return this.cashPayments.data.pagination;
		},
		data() {
			try {
				const { data } = this.cashPayments.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		hasData() {
			const response = this.data.length;
			return !!response;
		},
		idsComputed() {
			const ids = [];
			this.ids.forEach((item, index) => {
				if (item) {
					ids.push(index);
				}
			});
			return ids;
		},
		statuses() {
			return CASH_PAYMENT_STATUSES;
		},
	},
	mounted() {
		this.$route.query.status = this.status;
		this.cashPayments.getCashPayments();
	},
	methods: {
		getDataFiltered(page) {
			if (typeof page === 'object') {
				this.filters = { ...this.filters, ...page };
			}

			const { query } = this.$route;
			const options = { ...query, ...this.filters, status: this.status };

			if (typeof page === 'number') {
				options[PAGINATION] = page;
			}

			this.cashPayments.getCashPayments(options);
		},
		proccessSelected(type) {
			if (!this.idsComputed.length) {
				return null;
			}
			const orders = this.idsComputed.join(',');
			const options = { config: { showCancelButton: true, icon: 'warning' } };

			const trans = { title: this.translate('swal_title'), message: this.translate('swal_message', { orders }) };
			let responseMessage = this.translate('orders_approved');
			if (type === 'reject') {
				trans.title = this.translate('swal_reject_title');
				trans.message = this.translate('swal_reject_message', { orders });
				responseMessage = this.translate('orders_rejected');
			}
			this.alert.confirmation(trans.title, trans.message, options, true).then(() => {
				const loading = new this.$Alert();
				loading.loading(this.translate('loading'), this.translate('loading_text'), { allowOutsideClick: false, allowEscapeKey: false });
				const payload = { type, ids: this.idsComputed };
				this.processPayments.processPayment(payload).then(() => {
					this.alert.toast('success', responseMessage, { timer: 6000 });
					this.ids = [];
				}).finally(() => {
					this.cashPayments.getCashPayments().finally(() => loading.close());
				});
			}).catch(() => {});
			return null;
		},
	},
};
</script>
